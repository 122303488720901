<template>
  <el-dialog
    class="addTeachBuild-dialog"
    :title="`${preTitle}教学楼`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="520px"
    :before-close="cancel">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="110px"
    >
      <el-form-item label="教学楼名" prop="buildName">
        <el-input v-model.trim="formData.buildName" />
      </el-form-item>
      <el-form-item label="类型" prop="buildType">
        <el-radio-group class="mb-1" v-model="formData.buildType">
          <el-radio-button
            v-for="{paramValue,paramName} in buildTypeList"
            :key="paramValue"
            :label="paramValue"
          >{{ paramName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-model="formData.campusId"
        label="所在校区"
        prop="campusId"
      >
        <el-select v-model="formData.campusId">
          <el-option
            v-for="{campusName, id} in campusList"
            :label="campusName"
            :value="id"
            :key="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="楼层总数" prop="floorNum">
        <el-input-number
          v-model="formData.floorNum"
          :min="1"
          :max="30" />
      </el-form-item>
      <el-form-item label="管理员" prop="managerName">
        <tool-tips-group is-flex
                         tips-content="在系统设置的用户管理模块中，当分配了属于‘教务处’【部门】的账号时，这些账号会在此处显示。<br/>（请注意：分配了‘教务处’【角色】的账号不会出现在此处。）">
          <el-select
            v-model="formData.managerName"
            @change="showManagerPhone"
          >
            <el-option
              v-for="{realName, id} in managerList"
              :label="realName"
              :value="realName"
              :key="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="管理员联系方式" prop="managerPhone">
        <el-input v-model.trim="formData.managerPhone" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('教学楼',addTeachBuild)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getUserAll } from '@/api/system/user-api'
import { addTeachBuild, getTeachBuildById } from '@/api/teachBuild'
import dialog from '@/vue/mixins/dialog'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  name: 'addTeachBuild',
  mixins: [dialog, campusSchMajorClasses],
  props: {
    buildTypeList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      formData: {
        buildName: '',
        campusId: '',
        campusName: '',
        floorNum: 1,
        id: null,
        buildType: '',
        managerName: '',
        managerPhone: '',
        managerUserId: '',
        remarks: ''
      },
      managerList: [],
      rules: {
        campusId: {
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        },
        managerName: {
          required: true,
          message: '请选择管理员',
          trigger: 'blur'
        },
        floorNum: {
          required: true,
          message: '请选择楼层总数',
          trigger: 'blur'
        },
        buildName: [
          {
            required: true,
            message: '请填写教学楼名',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit20WordsObj
        ],
        managerPhone: this.$store.state.commonValidate.validatorPhoneObj
      }
    }
  },
  mounted () {
    this.getCampusList()
    this.getManagerList()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getTeachBuildById)
      }
    })
  },
  methods: {
    addTeachBuild, // 保存api
    // 获取管理员名单
    getManagerList () {
      //  部门id为'1552288360075182082'(基础信息-部门管理-教务处id) 的用户list
      getUserAll({ deptId: '1552288360075182082' }).then((res) => {
        this.managerList = res.data
      })
    },
    // 展示管理员手机号码
    showManagerPhone (e) {
      const result = this.managerList.find((item) => {
        return item.realName === e
      })
      this.formData.managerPhone = result.phone
      this.formData.managerUserId = result.id
    }
  }
}
</script>
