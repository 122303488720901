var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"addTeachBuild-dialog",attrs:{"title":(_vm.preTitle + "教学楼"),"visible":_vm.show,"close-on-click-modal":false,"width":"520px","before-close":_vm.cancel},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save('教学楼',_vm.addTeachBuild)}}},[_vm._v("确 定")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"110px"}},[_c('el-form-item',{attrs:{"label":"教学楼名","prop":"buildName"}},[_c('el-input',{model:{value:(_vm.formData.buildName),callback:function ($$v) {_vm.$set(_vm.formData, "buildName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.buildName"}})],1),_c('el-form-item',{attrs:{"label":"类型","prop":"buildType"}},[_c('el-radio-group',{staticClass:"mb-1",model:{value:(_vm.formData.buildType),callback:function ($$v) {_vm.$set(_vm.formData, "buildType", $$v)},expression:"formData.buildType"}},_vm._l((_vm.buildTypeList),function(ref){
var paramValue = ref.paramValue;
var paramName = ref.paramName;
return _c('el-radio-button',{key:paramValue,attrs:{"label":paramValue}},[_vm._v(_vm._s(paramName)+" ")])}),1)],1),_c('el-form-item',{attrs:{"label":"所在校区","prop":"campusId"},model:{value:(_vm.formData.campusId),callback:function ($$v) {_vm.$set(_vm.formData, "campusId", $$v)},expression:"formData.campusId"}},[_c('el-select',{model:{value:(_vm.formData.campusId),callback:function ($$v) {_vm.$set(_vm.formData, "campusId", $$v)},expression:"formData.campusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":campusName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"楼层总数","prop":"floorNum"}},[_c('el-input-number',{attrs:{"min":1,"max":30},model:{value:(_vm.formData.floorNum),callback:function ($$v) {_vm.$set(_vm.formData, "floorNum", $$v)},expression:"formData.floorNum"}})],1),_c('el-form-item',{attrs:{"label":"管理员","prop":"managerName"}},[_c('tool-tips-group',{attrs:{"is-flex":"","tips-content":"在系统设置的用户管理模块中，当分配了属于‘教务处’【部门】的账号时，这些账号会在此处显示。<br/>（请注意：分配了‘教务处’【角色】的账号不会出现在此处。）"}},[_c('el-select',{on:{"change":_vm.showManagerPhone},model:{value:(_vm.formData.managerName),callback:function ($$v) {_vm.$set(_vm.formData, "managerName", $$v)},expression:"formData.managerName"}},_vm._l((_vm.managerList),function(ref){
var realName = ref.realName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":realName,"value":realName}})}),1)],1)],1),_c('el-form-item',{attrs:{"label":"管理员联系方式","prop":"managerPhone"}},[_c('el-input',{model:{value:(_vm.formData.managerPhone),callback:function ($$v) {_vm.$set(_vm.formData, "managerPhone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.managerPhone"}})],1),_c('el-form-item',{attrs:{"label":"备注","prop":"remarks"}},[_c('el-input',{model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.remarks"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }