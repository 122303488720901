<template>
  <table-view
    class="charging-fee-record"
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        :queryInfo.sync="queryInfo"
        :tool-list="['keyword']"
        label-width="55px"
        @on-search="renderTable(1)">
        <el-form-item label="校区">
          <el-select v-model="queryInfo.campusId" @change="renderTable(1)" clearable filterable size="small">
            <el-option v-for="{campusName,id} in campusList" :key="id" :label="campusName" :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="queryInfo.buildType" clearable filterable size="small">
            <el-option v-for="{paramValue,paramName} in buildTypeList" :key="paramValue" :label="paramName"
                       :value="paramValue" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button" v-permission="['teachBuild:edit']">
        <upload-excel
          :loading.sync="loading"
          :api="$http.importExcelHttp.importTeachBuild"
          @on-download="$http.exportExcelHttp.exportTeachBuildTemplate()"
          @on-success="renderTable(1)" />
        <el-button type="primary" size="mini" @click="showDialog()">添加教学楼</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="campusName" label="所在校区" width="150" />
      <el-table-column prop="campusName" label="类型" width="100" align="center">
        <template v-slot="{row}">
          <el-tag :type="row.buildType==='classroom'?'primary':'success'">
            {{ $http.getParamName(row.buildType, buildTypeList)
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="buildName" label="教学楼名" width="150" />
      <el-table-column prop="floorNum" label="楼层总数" width="150" />
      <el-table-column prop="managerName" label="管理员" width="150" />
      <el-table-column prop="managerPhone" label="联系方式" width="150" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{row}">
          <el-button v-permission="['teachBuild:edit']" type="primary" size="mini" @click="showDialog(row.id)">编辑
          </el-button>
          <el-button v-permission="['teachBuild:del']" size="mini" type="danger"
                     @click="del(deleteTeachBuild,row.id,`${row.campusName}校区的教学楼-${row.buildName}`)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-build ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" :build-type-list="buildTypeList"
               @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getTeachBuildList, deleteTeachBuild } from '@/api/teachBuild'
import { getCampusList } from '@/api/campus'
import addBuild from './dialog/addTeachBuild.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    addBuild
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        campusId: null,
        buildType: null
      },
      buildTypeList: [], // 教学楼类型
      campusList: [],
      managerList: []
    }
  },
  async mounted () {
    await Promise.all([
      this.getCampusList(),
      this.renderTable(),
      this.$http.getParamListByKey('buildType', this, 'buildTypeList')
    ])
  },
  methods: {
    deleteTeachBuild, // 删除api
    // 获取校区信息
    getCampusList () {
      return getCampusList({}).then((res) => {
        this.campusList = res.data.list
      })
    },
    // 获取宿舍楼列表
    async renderTable (pageNum) {
      await this.getTableData(getTeachBuildList, pageNum)
    },
    // 获取建筑类型 list
    async getBuiltTypeList () {
      try {
        const res = await this.$http.getSysDictionaryByKey('buildType')
        this.buildTypeList = res.data
      } catch (e) {
      }
    }
  }
}
</script>
