var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{staticClass:"charging-fee-record",attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"queryInfo":_vm.queryInfo,"tool-list":['keyword'],"label-width":"55px"},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"校区"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},on:{"change":function($event){return _vm.renderTable(1)}},model:{value:(_vm.queryInfo.campusId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "campusId", $$v)},expression:"queryInfo.campusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":campusName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"类型"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},model:{value:(_vm.queryInfo.buildType),callback:function ($$v) {_vm.$set(_vm.queryInfo, "buildType", $$v)},expression:"queryInfo.buildType"}},_vm._l((_vm.buildTypeList),function(ref){
var paramValue = ref.paramValue;
var paramName = ref.paramName;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":paramValue}})}),1)],1)],1),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:(['teachBuild:edit']),expression:"['teachBuild:edit']"}],staticClass:"header-button"},[_c('upload-excel',{attrs:{"loading":_vm.loading,"api":_vm.$http.importExcelHttp.importTeachBuild},on:{"update:loading":function($event){_vm.loading=$event},"on-download":function($event){return _vm.$http.exportExcelHttp.exportTeachBuildTemplate()},"on-success":function($event){return _vm.renderTable(1)}}}),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("添加教学楼")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"campusName","label":"所在校区","width":"150"}}),_c('el-table-column',{attrs:{"prop":"campusName","label":"类型","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.buildType==='classroom'?'primary':'success'}},[_vm._v(" "+_vm._s(_vm.$http.getParamName(row.buildType, _vm.buildTypeList))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"buildName","label":"教学楼名","width":"150"}}),_c('el-table-column',{attrs:{"prop":"floorNum","label":"楼层总数","width":"150"}}),_c('el-table-column',{attrs:{"prop":"managerName","label":"管理员","width":"150"}}),_c('el-table-column',{attrs:{"prop":"managerPhone","label":"联系方式","width":"150"}}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注"}}),_c('el-table-column',{attrs:{"label":"操作","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['teachBuild:edit']),expression:"['teachBuild:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id)}}},[_vm._v("编辑 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['teachBuild:del']),expression:"['teachBuild:del']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.deleteTeachBuild,row.id,((row.campusName) + "校区的教学楼-" + (row.buildName)))}}},[_vm._v(" 删除 ")])]}}])})],1),(_vm.visibleDialog)?_c('add-build',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog,"build-type-list":_vm.buildTypeList},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }